.table-tiempo-carga {
    border-collapse: collapse;
    margin: 30px auto;
    min-width: 40vw;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

  .table-tiempo-carga thead tr {
    /*   background-color: #009879; */
      color: black;
      text-align: center;
      font-size:10px;
      border-bottom: 1px solid #f0eeee;
    }
    
    .table-tiempo-carga th,
    .table-tiempo-carga td {
      font-size: 1rem !important;
      padding: 10px;
    }
    .table-tiempo-carga td:last-of-type{
      padding-right:40px;
    }
    .table-tiempo-carga td:first-of-type{
      padding-left: 40px;
    }
    
    .table-tiempo-carga tbody tr {
      text-align: center;
      font-size:0.8rem;
      border-bottom: 1px solid #f0eeee;
      color: darkslategray;
      background-color: #fffdfd;
    }
    
    
    .table-tiempo-carga tbody tr:hover {
      background-color: #f9f9f9;
      cursor: pointer;
      
    }