.table-horario {
    border-collapse: collapse;
    margin: 30px auto;
    font-size: 0.9em;
    min-width: 400px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

  .table-horario thead tr {
    /*   background-color: #009879; */
      color: black;
      text-align: center;
      font-size:10px;
      border-bottom: 1px solid #f0eeee;
    }
    
    .table-horario th,
    .table-horario td {
      padding: 10px;
    }
    .table-horario td:last-of-type{
      padding-right:40px;
    }
    .table-horario td:first-of-type{
      padding-left: 40px;
    }
    
    .table-horario tbody tr {
      text-align: center;
      font-size:10px;
      border-bottom: 1px solid #f0eeee;
      color: darkslategray;
      background-color: #fffdfd;
    }
    
    
    .table-horario tbody tr:hover {
      background-color: #f9f9f9;
      cursor: pointer;
      
    }